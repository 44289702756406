import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import { useEvents } from '@/providers/event';
import { useRouter } from 'next/router';

export default function useEstimatesQuickFilterActions() {
	const router = useRouter();
	const creatable = usePermissions( permissions.estimates.write );
	
	const event = useEvents();
	
	return [
		creatable && {
			name   : 'View Open Estimates',
			onClick: () => {
				router.push( {
					pathname: router.asPath.split( '?' )[ 0 ],
					query   : {
						s: encodeURIComponent( btoa( JSON.stringify( {
							filters: [ {
								id   : 'status',
								value: {
									$in : [ 'SENT', 'VIEWED' ],
									temp: { checked: true },
								},
							} ],
						} ) ) ),
					},
				}, undefined, { shallow: true } ).then();
				event.emit( 'url.filter', true );
			},
		},
	];
}

