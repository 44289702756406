import CustomCheckBox from '@/components/customCheckBox';
import DateTextFieldSlot from '@/components/muiDatePickerFields/dateTextFieldSlot';
import { safeConvertToZonedTime } from '@/helpers/safeFormat';
import { useModalControls } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { CommerceType } from '@/types/schema';
import { FormControlLabel, Grid, ListItemButton, ListItemText, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ApproveAndInvoiceOrMoveModal( {
	convertType,
	onSave,
}: {
	convertType: CommerceType,
	onSave: ( option: string, keepNumber?: boolean, customCreatedDate?: Date ) => Promise<void>
} ) {
	const { closeModal } = useModalControls();
	const [ selectedOption, setSelectedOption ] = useState( 'approve' );
	const [ preserveNumber, setPreserveNumber ] = useState( false );
	const [ customCreatedAt, setCustomCreatedAt ] = useState( null );
	const { t } = useTranslation();
	const toInvoice = convertType === 'INVOICE';
	return (
		<ResponsiveModalContainer
			title={toInvoice ? t( 'commerce:approve-invoice' ) : t( 'commerce:approve-order' )}
			saveButtonProps={{
				disabled: !selectedOption,
			}}
			onClose={() => {
				setSelectedOption( '' );
				closeModal();
			}}
			onSave={async () => {
				await onSave( selectedOption, preserveNumber, customCreatedAt );
				closeModal();
			}}>
			<Grid
				container
				spacing={1}
				sx={{
					'.MuiListItemButton-root': {
						p      : 2,
						display: 'block',
						height : { xs: '100%', sm: 290 },
						
					},
				}}>
				<Grid item xs={12} sm={6}>
					<ListItemButton
						sx={{
							border      : 4,
							borderRadius: 3,
							borderColor : selectedOption === 'approve' ? 'primary.main' : 'divider',
						}}
						onClick={() => setSelectedOption( 'approve' )}>
						<Typography variant='h5' mb={2}>{t( 'commerce:keep-estimate-title' )}</Typography>
						<ListItemText sx={{ color: 'text.secondary' }}>
							{toInvoice ? t( 'commerce:keep-estimate' ) : t( 'commerce:keep-estimate-order' )}
						</ListItemText>
						<Typography>{`Change ${toInvoice ? 'Invoice' : 'Order'} created date (Optional)`}</Typography>
						<DatePicker<Date>
							value={safeConvertToZonedTime( customCreatedAt ) || null}
							slots={{ textField: DateTextFieldSlot }}
							onChange={( date ) => setCustomCreatedAt( date )}
						/>
						<FormControlLabel
							sx={{ pt: 2 }}
							label='Preserve the estimate number'
							control={(
								<CustomCheckBox
									checked={preserveNumber}
									sx={{ ml: 1 }}
									onChange={( e, checked ) => setPreserveNumber( checked )}
								/>
							)}
						/>
					</ListItemButton>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ListItemButton
						disableGutters
						sx={{
							border      : 4,
							borderRadius: 3,
							borderColor : selectedOption === 'move' ? 'primary.main' : 'divider',
						}}
						onClick={() => setSelectedOption( 'move' )}>
						<Typography variant='h5' mb={2}>{toInvoice
							? t( 'commerce:move-to-invoice-title' )
							: t( 'commerce:move-to-order-title' )}
						</Typography>
						<ListItemText sx={{ color: 'text.secondary' }}>
							{toInvoice ? t( 'commerce:move-to-invoice' ) : t( 'commerce:move-to-order' )}
						</ListItemText>
					</ListItemButton>
				</Grid>
			</Grid>
		</ResponsiveModalContainer>
	);
}
