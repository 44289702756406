import Attachment from '@/components/fileUploading/attachment';
import { ListItem, ListItemText, Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LineItemsRead } from '../../../../data/management/lineItem.graphql';
import GraphqlProvider from '../../../../data/query/graphqlProvider';
import currencyFormat from '../../../../helpers/currencyFormat';
import type { LineItemMultiOutput, Order, QueryLineItemsReadArgs } from '../../../../types/schema';

export default function RowPanel( { row, hidePrivateNote }: { row: Order, hidePrivateNote?: boolean } ) {
	const { t } = useTranslation();
	return (
		<GraphqlProvider<LineItemMultiOutput, QueryLineItemsReadArgs>
			queryKey='lineItems'
			query={LineItemsRead}
			subscription={{ LINE_ITEM: row.id }}
			variables={{ options: { filter: { order: row.id } } }}>
			{( { items: lineItems } ) => (
				<Paper sx={{ width: '100%', maxHeight: 400, overflow: 'overlay', border: 0 }}>
					{lineItems.length ? lineItems.map( ( lineItem ) => (
						<ListItem
							key={lineItem.id}
							sx={{
								'px'                                : 1,
								'py'                                : .5,
								':not(.MuiListItem-root:last-child)': {
									borderBottom: 1,
									borderColor : 'divider',
								},
							}}>
							<ListItemText
								secondaryTypographyProps={{ component: 'div' }}
								primaryTypographyProps={{ component: 'div' }}
								primary={(
									<Stack direction='row' alignItems='center' spacing={1}>
										<Typography variant='subtitle2'>{lineItem.name}</Typography>
										<Typography>{lineItem.quantity} {lineItem.unit}</Typography>
										<Typography>x</Typography>
										<Typography>{currencyFormat( lineItem.price )}</Typography>
									</Stack>
								)}
								secondary={lineItem?.prices?.filter( ( price ) => !price.metadata?.externalTax )
									?.map( ( fee ) => (
										<Typography key={fee.id}>
											{`${fee.name} ${fee.isPercent
												? `(${fee.value}%)`
												: ` - ${currencyFormat( fee.value )}`}`}
										</Typography>
									) )}
							/>
							<Stack direction='row' spacing={1} alignItems='center'>
								<Typography>
									{currencyFormat(
										lineItem.price
										* lineItem.quantity
										+ lineItem?.prices.reduce( ( values, fee ) => fee.value + values, 0 ) )}
								</Typography>
							</Stack>
						</ListItem>
					) ) : (
						<Typography variant='subtitle2' p={.5} color='text.secondary'>
							{t( 'commerce:no-line-items' )}
						</Typography>
					)}
					{row.metadata.signature && (
						<Stack p={1}>
							<Typography>Signed</Typography>
							<Attachment
								removeDownload
								src={row.metadata.signature}
								imageSX={{ width: 40, height: 40, objectFit: 'cover', mb: 0 }}
							/>
						</Stack>
					)}
					{row.metadata?.privateNote && !hidePrivateNote && (
						<Typography color='text.secondary' p={1}>
							{t( 'commerce:private-note' )} {row.metadata.privateNote}
						</Typography>
					)}
					{/*{row?.notes && (*/}
					{/*	<Typography color='text.secondary' p={1}>*/}
					{/*		Note: {row.notes}*/}
					{/*	</Typography>*/}
					{/*)}*/}
				</Paper>
			)}
		</GraphqlProvider>
	
	);
}
