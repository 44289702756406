import { useModalControls } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { Grid, ListItemButton, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ApproveAndWorkOrderModal( { onSave }: { onSave: () => Promise<void> } ) {
	const { closeModal } = useModalControls();
	const { t } = useTranslation();
	return (
		<ResponsiveModalContainer
			title={t( 'commerce:approve-work-order' )}
			onClose={() => {
				closeModal();
			}}
			onSave={async () => {
				await onSave();
				closeModal();
			}}>
			<Grid
				container
				spacing={1}
				sx={{
					'.MuiListItemButton-root': {
						p      : 2,
						display: 'block',
						height : { xs: '100%', sm: 250 },
						
					},
				}}>
				<Grid item xs={12} sm={6}>
					<ListItemButton
						disableGutters
						sx={{
							border      : 4,
							borderRadius: 3,
							borderColor : 'primary.main',
						}}>
						<Typography variant='h5' mb={2}>{t( 'commerce:convert-to-work-order-title' )}</Typography>
						<ListItemText sx={{ color: 'text.secondary' }}>
							{t( 'commerce:convert-to-work-order' )}
						</ListItemText>
					</ListItemButton>
				</Grid>
			</Grid>
		</ResponsiveModalContainer>
	);
}
